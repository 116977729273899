import React, { useEffect } from "react"
import Login from './login';
import { useAxios } from './axios';
import User from "./user";
import Uploader from "./uploader";
import logoImageUrl from './assets/logo.png'

export default function App() {
  const { axios, signedIn } = useAxios();
  useEffect(() => {
    axios.get('/health')
      .then(response => {
        console.log(response.data)
      })
  }, [])

  return <div style={{
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0'
  }}>
    <img src={logoImageUrl} />
    <User />
    {
      !signedIn && <>
        <Login />
      </>
    }
    {
      signedIn && <>
        <Uploader />
      </>
    }
    <div style={{
      color: '#888',
      fontSize: '0.8rem'
    }}>mealion uploader v1.13.8</div>
  </div>

}
