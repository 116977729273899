import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app'
import { AxiosProvider } from './axios';

const app = document.getElementById('app');

ReactDOM.createRoot(app).render(
  <AxiosProvider>
    <App />
  </AxiosProvider>
)
