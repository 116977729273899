import { useEffect, useState } from "react";
import { useAxios } from "./axios";

export default function User() {
  const { axios, signedIn } = useAxios();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (signedIn) {
      (async () => {
        const response = await axios.get('/my');
        setUser(response.data);
      })();
    }
  }, [signedIn, axios])

  return <div>
    <div style={{
      color: '#888'
    }}>
      {signedIn && user !== null ? `Signed in as ${user.nickname}` : 'Please sign-in'}
    </div>
  </div>
}
