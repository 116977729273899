import React, { useCallback, useEffect, useMemo, useState } from "react"

export default function Image({ file }) {
  const startTime = useMemo(() => new Date(), []);
  const [loadTime, setLoadTime] = useState(null);

  const [image, setImage] = useState(null);
  const [size, setSize] = useState(null);
  const src = useMemo(() => {
    return URL.createObjectURL(file)
  }, [file])

  const onLoad = useCallback((e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setSize([naturalWidth, naturalHeight]);
  }, [])

  return <div>
    <img src={src} onLoad={onLoad} width="400px" />
    {
      size && <div>
        Image size: {size[0]} x {size[1]}
      </div>
    }
    <div>
      File size: {file.size} bytes
    </div>
  </div>;
}
