export const getImageSize = (file) => {
  const url = URL.createObjectURL(file);
  const image = new Image();
  const promise = new Promise(
    (resolve) => image.onload = () => {
      resolve([image.width, image.height]);
    }
  );
  image.src = url;
  return promise;
}
