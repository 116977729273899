import React, { useCallback, useEffect, useState } from "react"
import { useAxios } from "./axios";

export default function Login() {
  const [stage, setStage] = useState('none');
  const [phoneText, setPhoneText] = useState('');
  const [codeText, setCodeText] = useState('');
  const { axios, setToken } = useAxios();

  const startAuth = useCallback(() => {
    (async () => {
      await axios.post('/auth', { phone: phoneText });
      setStage('auth_started')
    })();
  }, [phoneText, axios]);

  const signIn = useCallback(() => {
    (async () => {
      const response = await axios.post('/certifications', { phone: phoneText, code: codeText });
      setToken(response.data.token)
      setStage('signed_in');
      localStorage.setItem('auth/token', response.data.token);
    })();
  }, [phoneText, codeText, axios])

  const signOut = useCallback(() => {
    setToken(null);
    localStorage.removeItem('auth/token');
  }, [])

  // load from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem('auth/token');
    if (!storedToken) {
      return;
    }
    setToken(storedToken);
    setStage('signed_in');
  }, [])

  return <div
    style={{
      display: 'inline-flex',
      flexDirection: 'column',
      rowGap: '8px',
      margin: '8px',
      padding: '12px',
      border: '1px solid #ddd',
      borderRadius: 4,
    }}
  >
    <h1>로그인</h1>
    {
      stage === 'none' && <>
        <input placeholder="휴대폰 번호" type="text" onChange={(e) => { setPhoneText(e.target.value) }} />
        <button onClick={() => startAuth()}>인증 시작</button>
      </>
    }
    {
      stage === 'auth_started' && <>
        <input placeholder="코드" type="text" onChange={(e) => { setCodeText(e.target.value) }} />
        <button onClick={() => signIn()}>로그인</button>
      </>
    }
    {
      stage === 'signed_in' && <>
        <button onClick={() => signOut()}>로그아웃</button>
      </>
    }
  </div>
}
