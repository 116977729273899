import { useCallback, useEffect, useMemo, useState } from "react"
import { useAxios } from "./axios";
import { getImageSize } from "./get-image-size";
import Image from "./image";

export default function Uploader() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalSize, setOriginalSize] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const { axios } = useAxios();


  useEffect(() => {
    if (imageUrl === null) return;
    (async () => {
      const blob = await axios.get(
        imageUrl,
        {
          responseType: 'blob',
          headers: {
            Authorization: null
          }
        }
      ).then(response => response.data);
      setImageBlob(blob);
    })();
  }, [imageUrl])

  useEffect(() => {
    if (selectedFile === null) return;
    (async () => {
      const size = await getImageSize(selectedFile)
      setOriginalSize(size);
    })();
  }, [selectedFile])

  const upload = useCallback(() => {
    (async () => {
      // upload file
      let response = await axios.post('/files', { names: [selectedFile.name] });
      const { id, uploadUrl } = response.data[0];

      await axios.put(uploadUrl, selectedFile, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': null
        }
      });

      // download resized images
      const url = await axios.post('/files/image-download-urls', {
        file: [id],
        quality: 70
      })
        .then(
          response => response.data[0].url
        )

      setImageUrl(url);
    })();
  }, [selectedFile, axios]);


  return <div style={{
    display: 'flex',
    alignItems: 'center'
  }}>
    <div style={{
      display: 'inline-flex',
      flexDirection: 'column',
      rowGap: '8px',
      margin: '8px',
      padding: '12px',
      border: '1px solid #ddd',
      borderRadius: 4,
    }}>
      <h1>업로드</h1>
      <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
      <button onClick={() => upload()}>업로드</button>

      {
        selectedFile && <Image file={selectedFile} />
      }
    </div>
    {
      imageBlob && <div style={{
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '0 8px'
      }}>
        <div>→</div>
        <div>-{Math.floor((1 - imageBlob.size / selectedFile.size) * 100)}%</div>
      </div>
    }
    {
      imageBlob && <div style={{
        display: 'inline-flex',
        flexDirection: 'column',
        rowGap: '8px',
        margin: '8px',
        padding: '12px',
        border: '1px solid #ddd',
        borderRadius: 4
      }}>
        <h1>이미지 보기</h1>
        {
          <Image file={imageBlob} />
        }
      </div>
    }
  </div>
}
