import axios from 'axios';
import React, { useContext, useMemo, useState } from 'react';

const AxiosContext = React.createContext()

export const useAxios = () => {
  return useContext(AxiosContext);
}

export const AxiosProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const instance = useMemo(() => axios.create({
    baseURL: 'https://dev.mealion.kr/api',
    headers: {
      Authorization: token !== null ? `Bearer ${token}` : undefined
    }
  }), [token])

  return <AxiosContext.Provider value={{ axios: instance, setToken, signedIn: token !== null }}>
    {children}
  </AxiosContext.Provider>
}
